<script>

import {getContext} from 'svelte';
import {_} from 'svelte-i18n';
import Navigation from 'Navigation.svelte';
import LayoutCentered from 'LayoutCentered.svelte';

const auth = getContext('AUTH_SERVICE');

let errorMessage = '';
let key = '', secret = '';
let signInBtn;

async function handleSubmit(event) {
	let frm = event.target;
	if (!frm.checkValidity()) {
		return;
	}
	errorMessage = '';
	signInBtn.style.disabled = true;

	let errorHandler = async (response) => {
		signInBtn.style.disabled = false;
		errorMessage = 'An error occurred, please check your credentials';
		let json = await response.json();
		if(json){
			if(json.errors && json.errors[0].title) {
				errorMessage = json.errors[0].title;
			}
		}
		return null;
	}

	let res = auth.signIn({key, secret}, errorHandler).then(profile => {
		if(profile) {
			document.location.href = '/private/dashboard';
			signInBtn.style.disabled = false;
		}
	});
	return false;
}

</script>

<style>

h1 {
	@apply font-bold;
}

button {
	/*@apply bg-blue-500 text-white font-bold py-2 px-4 rounded;*/
	@apply px-1 py-1 rounded inline-flex items-center;
	background-color: var(--blue);
	color: var(--txt);
	font-weight: 400;
	padding: .4375rem .625rem;
}

/*button:disabled,*/
/*button[disabled] {*/
/*	@apply bg-blue-100;*/
/*}*/

button:hover {
	@apply bg-blue-700;
}

button:focus {
	@apply outline-none shadow-outline;
}

input {
	/*@apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight mb-4;*/
	display: block;
	width: 100%;
	height: calc(1.6em + 1.875rem + 2px);
	padding: .9375rem 1.25rem;
	font-size: .9375rem;
	font-weight: 400;
	line-height: 1.6;
	color: var(--txt-input);
	background-color: var(--bg-contrast);
	background-clip: padding-box;
	border: 1px solid var(--bg-contrast);
	border-radius: .375rem;
	margin-bottom: 24px;
}

input:focus {
	@apply outline-none shadow-outline;
}

input:not(:focus):not(:placeholder-shown):invalid {
	@apply bg-red-700;
}


</style>

<Navigation/>

<LayoutCentered>
	<form on:submit|preventDefault={handleSubmit} class="max-w-xs">
		<h1>WIS</h1>

		<p class="mb-4">
			{$_('msg-signin')}
		</p>

		{#if errorMessage }
			<p class="text-red-500 text-xs italic mt-4 mb-4">{ errorMessage }</p>
		{/if}

		<input bind:value={key} autocomplete="username" placeholder="{$_('email')}" required type="email">

		<input bind:value={secret} autocomplete="current-password" placeholder="{$_('password')}" required type="password">

		<button bind:this={signInBtn} type="submit">{$_('sign in')}</button>
	</form>
</LayoutCentered>
