<svelte:head>
	<title>WIS : {$_('locations')}</title>
	<link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
		  integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
		  crossorigin=""/>
	<script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
			integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
			crossorigin="" on:load={init}></script>
</svelte:head>
<script>

import {_} from 'svelte-i18n';

import ButtonBar from 'buttons/ButtonBar.svelte';
import Location from 'Location.svelte';
import {getContext} from 'svelte';
import {findById} from 'utils';

const {open} = getContext('simple-modal');
const client = getContext('REST_CLIENT');

// const RED = '#FF0000';
const BLUE = '#3A9BFC';
const LIGHT_BLUE = '#2468AB';

let items, map;
let LAT_LONGS = [];

function init() {
	map = L.map('map');

	L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="http://osm.org/copyright">OSM</a>'
	}).addTo(map);

	client.get('/locations').then(async (res) => {
		items = await res.json();
		putItemsOnMap(items, map);
	})
}

function putItemsOnMap(items, map) {
	for (let i = 0, len = items.length; i < len; i++) {
		let ll = new L.LatLng(items[i].latitude, items[i].longitude);

		let marker = new L.CircleMarker(ll, {
			fillColor: BLUE,
			fillOpacity: 0.8,
			color: LIGHT_BLUE,
			weight: 2,
			opacity: 1,
			id: items[i].id
		}).setRadius(8);

		marker.on('click', function (event) {
// 				console.log('Location clicked ', event.target.options.id);
			showEdit(findById(items, event.target.options.id));
		});

		marker.addTo(map);
		LAT_LONGS.push(ll);
	}

	if (LAT_LONGS.length) {
		map.fitBounds(new L.LatLngBounds(LAT_LONGS), {
			padding: [50, 50]
		});
	}
}

function editCallback(e) {
	console.log('editCallback', e)
	// putItemsOnMap([e.detail], map)
}

function handleAddClick(e) {
	showEdit({});
}

function showEdit(o) {
	open(Location, {item: o, callback: editCallback});
}

</script>
<style>

#map {
	@apply w-full;
	height: calc(100% - 50px);
	z-index: 1;
}

</style>
<section class="h-full">
	<ButtonBar on:add={handleAddClick}/>
	<div id="map"></div>
</section>
