<svelte:head>
	<title>WIS : {$_('presentations')}</title>
</svelte:head>
<script>

import {onMount, getContext} from 'svelte';
import {navigateTo} from 'svelte-router-spa';

import ButtonBar from 'buttons/ButtonBar.svelte';
import Tile from 'Tile.svelte';
import {_} from 'svelte-i18n';
import PNotify from 'pnotify/PNotify.bundle';
import {ConfirmWithSlidesModal, ReConfirmModal} from 'utils';

import {presentations} from "./stores";

const client = getContext('REST_CLIENT');
const auth = getContext('AUTH_SERVICE');
let items = {planned:[],inactive:[],embedded:[],shared:[],embeddable:[]};

// function loadPresentations() {
// 	presentations.init(`/presentations?filter[group_id]=${auth.profile.group_id}&sort=label`);
// }

onMount(async () => {
	const res = await client.get(`/presentations/dashboard`);
	items = await res.json();
});

function handleClick(id) {
	navigateTo(`/private/presentation/${id}`);
}

function handleRemoveClick(id, i) {
	let notice = ConfirmWithSlidesModal('Are you sure?', 'Please confirm you wish to delete this');
	let errorHandler = async function (res) {
		if (res.status === 428) {
			let notice = ReConfirmModal('Presentation is in use', 'This presentation is in use in a schedule. Please confirm again that you wish to delete this.');
			notice.on('confirm', () => {
				presentations.remove(id, i, 'force_delete=1').then(res => {
					PNotify.success({
						title: $_('presentation') + ' ' + $_('deleted')
					});
				});
			});
		} else {
			let json = await res.json();
			PNotify.error({
				title: res.status + ' ' + res.statusText,
				text: await json.errors[0].detail ? json.errors[0].detail : json.errors[0].title
			});
			throw Error(json.errors[0].title);
		}
	};
	let errorHandlerSlides = async function (res) {
		if (res.status === 428) {
			let notice = ReConfirmModal('Presentation is in use', 'This presentation is in use in a schedule. Please confirm again that you wish to delete this.');
			notice.on('confirm', () => {
				presentations.remove(id, i, 'relations[Slides]&force_delete=1').then(res => {
					PNotify.success({
						title: $_('presentation') + ' ' + $_('deleted')
					});
				});
			});
		} else {
			let json = await res.json();
			PNotify.error({
				title: res.status + ' ' + res.statusText,
				text: await json.errors[0].detail ? json.errors[0].detail : json.errors[0].title
			});
			throw Error(json.errors[0].title);
		}
	};
	notice.on('confirm', () => {
		presentations.remove(id, i, null, errorHandler).then(res => {
			PNotify.success({
				title: $_('presentation') + ' ' + $_('deleted')
			});
		});
	});
	notice.on('confirm-slides', () => {
		presentations.remove(id, i, 'relations[Slides]', errorHandlerSlides).then(res => {
			PNotify.success({
				title: $_('presentation') + ' ' + $_('deleted')
			});
		});
	});
}

function handleAddClick(e) {
	presentations.create({
		label: $_('new_presentation')
	}).then(res => res.json()).then(data => {
		PNotify.success({
			title: $_('presentation') + ' ' + $_('created')
		});
		handleClick(data.id);
	});
}

</script>
<style>

header {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
	font-size: 1.2rem;
	font-weight: bold;
	background-color: #1a202c;
	color: #fff;
	padding: 4px;
	z-index: 1;
}

.list > div {
	@apply flex flex-row;
	flex-flow: row wrap;
	padding: 0 0 0 4px;
}

.list div > .tile {
	padding: 4px 4px 4px 0;
	@apply cursor-pointer;
}

</style>
<section>
	<ButtonBar on:add={handleAddClick}/>
	<div class="h-full overflow-y-auto pb-40">
		{#await items}
			LOADING
		{:then}
			<div class="list">
				<header>{$_('planned presentations')}</header>
				<div>
					{#if items.planned.length}
						{#each items.planned as item, i}
							<div class="tile clickable">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
									  showRemoveButton
									  on:click={(e)=>handleClick(item.id)}
									  on:handleRemoveClick={(e)=>{handleRemoveClick(item.id, i)}}/>
							</div>
						{/each}
					{:else}
						{$_('no presentations planned')}
					{/if}
				</div>
				<header>{$_('inactive presentations')}</header>
				<div>
					{#if items.inactive.length}
						{#each items.inactive as item, i}
							<div class="tile clickable">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
									  showRemoveButton
									  on:click={(e)=>handleClick(item.id)}
									  on:handleRemoveClick={(e)=>{handleRemoveClick(item.id, i)}}/>
							</div>
						{/each}
					{:else}
						{$_('no inactive presentations')}
					{/if}
				</div>
				<header>{$_('shared presentations')}</header>
				<div>
					{#if items.shared.length}
						{#each items.shared as item, i}
							<div class="tile clickable">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
									  showRemoveButton
									  on:click={(e)=>handleClick(item.id)}
									  on:handleRemoveClick={(e)=>{handleRemoveClick(item.id, i)}}/>
							</div>
						{/each}
					{:else}
						{$_('no shared presentations')}
					{/if}
				</div>
			</div>
		{/await}
	</div>
</section>
