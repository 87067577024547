import {derived, writable} from 'svelte/store';
import HttpClient from "HttpClient";

const client = new HttpClient();

function FileStore() {
	const {subscribe, set, update} = writable([]);

	let endpoint = '/nodes/files';

	const init = async function () {
		//console.log(`STORE GET ${endpoint}`);
		return client.get(endpoint).then(async res => {
			const data = await res.json();
			set(data);
			return res;
		});
	};

	return {
		subscribe,
		init
	}
}

function RestStore(ep) {
	const {subscribe, set, update} = writable([]);

	let endpoint = ep;
	let _endpoint;

	const init = async function (ep) {
		if (!ep) {
			ep = endpoint;
		}
		if (!_endpoint) {
			_endpoint = ep;
		}
		// console.log(`STORE GET ${_endpoint}`);
		return client.get(_endpoint).then(async res => {
			const data = await res.json();
			set(data);
			return res;
		});
	};

	const create = async function (item, errorHandler) {
		//console.log(`STORE POST ${endpoint}`, item);
		return client.post(endpoint, item, errorHandler).then(async res => {
			init();
			return res;
		});
	};

	const put = async function (item, errorHandler) {
		//console.log(`STORE PUT ${endpoint}`, item);
		return client.put(endpoint + '/' + item.id, item, errorHandler).then(async res => {
			init();
			return res;
		});
	};

	const patch = async function (item, errorHandler) {
		//console.log(`STORE PATCH ${endpoint}`, item);
		return client.patch(endpoint + '/' + item.id, item, errorHandler).then(async res => {
			init();
			return res;
		});
	};

	const remove = async function (id, index, qs, errorHandler) {
		//console.log(`STORE DELETE ${endpoint}`, id);
		return client.delete(endpoint + '/' + id + (qs ? '?' + qs : ''), errorHandler).then(async res => {
			if (res.ok) {
				update(data => {
					if (!index) {
						let index = data.findIndex(item => item.id === id);
					}
					if (index > -1) {
						data.splice(index, 1);
					}
					return data;
				});
			}
			return res;
		});
	};

	return {
		subscribe,
		init,
		create,
		put,
		patch,
		remove
	}
}

export const presentations = new RestStore('/presentations');
export const clusters = new RestStore('/clusters');
export const files = new FileStore();

export const first_folder = derived(
	[files],
	([$files]) => {
		return $files.length ? $files[0] : undefined;
	}
);
