<svelte:head>
	<title>WIS : {$_('files')}</title>
</svelte:head>
<script>

import {getContext} from 'svelte';
import Tile from 'Tile.svelte';
import {ConfirmModal, ReConfirmModal} from 'utils';
import PNotify from 'pnotify/PNotify.bundle';
import {copy, findById, formatFileSize} from 'utils';
import Folder from 'Folder.svelte';

const {open, close} = getContext('simple-modal');
import {_} from 'svelte-i18n';

import {files, first_folder} from "./stores";
import NodesMenu from 'NodesMenu.svelte';

$:selectedValue = $first_folder;
let backup;
let showUnusedOnly = false;

const client = getContext('REST_CLIENT');

function handleFileRemoveClick(id) {
	let notice = ConfirmModal('Are you sure?', 'Please confirm you wish to delete this');
	let errorHandler = async function (res) {
		if (res.status === 428) {
			let notice = ReConfirmModal('File is in use', 'This file is in use. Deleting it will also remove it from all slides. Please confirm again that you wish to delete this.');
			notice.on('confirm', () => {
				client.delete(`/files/${id}?force_delete=1`, errorHandler).then((e) => {
					handleNodesUpdate();
				});
			});
		} else {
			let json = await res.json();
			PNotify.error({
				title: res.status + ' ' + res.statusText,
				text: await json.errors[0].detail ? json.errors[0].detail : json.errors[0].title
			});
			throw Error(json.errors[0].title);
		}
	};
	notice.on('confirm', () => {
		client.delete(`/files/${id}`, errorHandler).then((e) => {
			handleNodesUpdate();
		});
	});
}

async function handleNodesUpdate(item) {
	await files.init();
	if (item) {
		selectedValue = findById($files, item.id);
	} else {
		selectedValue = $files.length ? $files[0] : undefined;
	}
}

function handleOnClick(file) {
	window.open(file.url, '_blank');
}

function onCancel() {
	selectedValue = backup;
	close();
}

function handleEditClick(item) {
	backup = copy(item);
	open(Folder, {item: item, callback: handleNodesUpdate, cancel: onCancel}, {});
}

function handleDeleteClick(item) {
	let notice = ConfirmModal('Are you sure?', 'Please confirm you wish to delete this');
	let errorHandler = async function (res) {
		if (res.status === 428) {
			let notice = ReConfirmModal('Contents are in use', 'This folder contains files that are in use. Deleting it will also remove them from all slides. Please confirm again that you wish to delete this.');
			notice.on('confirm', () => {
				client.delete(`/nodes/${item.id}?force_delete=1`, errorHandler).then((e) => {
					handleNodesUpdate();
				});
			});
		} else {
			let json = await res.json();
			PNotify.error({
				title: res.status + ' ' + res.statusText,
				text: await json.errors[0].detail ? json.errors[0].detail : json.errors[0].title
			});
			throw Error(json.errors[0].title);
		}
	};
	notice.on('confirm', () => {
		client.delete(`/nodes/${item.id}`, errorHandler).then((e) => {
			handleNodesUpdate();
		});
	});
}

</script>
<style>

.tile {
	display: inline-block;
	padding: 4px 4px 4px 0;
	max-height: 192px;
}

.tile:not(.disabled) {
	@apply cursor-pointer;
}

header {
	height: 50px;
}

</style>
<section class="flex w-full h-full overflow-hidden">
	<div style="width: 192px">
		<NodesMenu type="MEDIA"
				   items={$files}
				   {selectedValue}
				   on:update={(e)=>handleNodesUpdate(e.detail)}
				   on:select={(e)=>{selectedValue=e.detail}}
		/>
		<label><input type="checkbox" bind:checked={showUnusedOnly}> {$_('unused only')}</label>
	</div>
	<div class="flex-1">
		{#await $files}
			LOADING
		{:then $files}
			{#each $files as item, i}
				{#if selectedValue }
					{#if (item.id == selectedValue.id) }
						<header class="w-full bg-gray-900 flex flex-row p-2">
							<div class="label w-full">
								{item.label}
							</div>
							{#if item.label !== "MEDIA" }
								<div class="flex">
									<button on:click={()=>handleDeleteClick(item)} class="delete-btn">✖ {$_('remove')}</button>
									<button on:click={()=>handleEditClick(item)} class="edit-btn">✎ {$_('edit')}</button>
								</div>
							{/if}
						</header>
						<div class="w-full h-full overflow-y-scroll">
							{#if item.content}
								{#each item.content as file}
									{#if !showUnusedOnly || (showUnusedOnly && file.use_count === 0) }
									<div class="tile">
										<Tile label={file.label}
											  subscript={formatFileSize(file.file_size) + ' / ' + file.use_count + 'x used'}
											  thumbnail={file.thumbnail}
											  showRemoveButton
											  on:click={(e)=>handleOnClick(file)}
											  on:handleRemoveClick={(props)=>handleFileRemoveClick(file.id)}
										/>
									</div>
									{/if}
								{/each}
							{/if}
						</div>
					{/if}
				{:else}
					<header class="w-full bg-gray-900 flex flex-row p-2">
						<div class="label w-full">
							&nbsp
						</div>
					</header>
					<div class="w-full h-full overflow-y-scroll">
					</div>
				{/if}
			{/each}
		{/await}
	</div>
</section>
