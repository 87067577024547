<script>

import {Router} from "svelte-router-spa";
import {setContext} from "svelte";
import HttpClient from "HttpClient";
import AuthService from "AuthService";
import Modal from "Modal.svelte";
import {locale} from "svelte-i18n";

import Layout from "./private/Layout.svelte";

import SignIn from "./public/SignIn.svelte";
import NotFound from "./public/NotFound.svelte";

import Dashboard from "./private/Dashboard.svelte";

import Presentations from "./private/Presentations.svelte";
import Presentation from "./private/Presentation.svelte";

import Schedule from "./private/Schedule.svelte";
import Files from "./private/Files.svelte";
import Settings from "./private/Settings.svelte";

import Users from "./private/Users.svelte";

import Screens from "./private/Screens.svelte";
import Locations from "./private/Locations.svelte";

const client = new HttpClient();
const auth_service = new AuthService(client);

setContext("REST_CLIENT", client);
setContext("AUTH_SERVICE", auth_service);

function isAuthenticated() {
	locale.set(
		auth_service.isAuthenticated ? auth_service.profile.locale : "en-us"
	);
	// console.log('isAuthenticated', auth_service.isAuthenticated, locale);
	return auth_service.isAuthenticated;
}

function isAdmin() {
	return auth_service.isAdmin();
}

const routes = [
	{
		name: "/",
		redirectTo: "sign-in",
	},
	{
		name: "sign-in",
		component: SignIn,
		onlyIf: {
			guard: () => {
				if (isAuthenticated()) {
					auth_service.signOut();
				}
				return true;
			},
			redirect: "/private/dashboard",
		},
	},
	{
		name: "404.html",
		component: NotFound,
	},
	{
		name: "private",
		layout: Layout,
		onlyIf: {
			guard: isAuthenticated,
			redirect: "/sign-in",
		},
		nestedRoutes: [
			{
				name: "dashboard",
				component: Dashboard,
			},
			{
				name: "dashboard/:cluster_id",
				component: Dashboard,
			},
			{
				name: "schedule",
				component: Schedule,
			},
			{
				name: "schedule/:cluster_id",
				component: Schedule,
			},
			{
				name: "presentations",
				component: Presentations,
			},
			{
				name: "presentation/:id",
				component: Presentation,
			},
			{
				name: "files",
				component: Files,
			},
			{
				name: "settings",
				component: Settings,
			},
			{
				name: "users",
				component: Users,
				onlyIf: {
					guard: isAdmin,
					redirect: "/private/dashboard",
				},
			},
			{
				name: "screens",
				component: Screens,
				onlyIf: {
					guard: isAdmin,
					redirect: "/private/dashboard",
				},
			},
			{
				name: "screens/:cluster_id",
				component: Screens,
				onlyIf: {
					guard: isAdmin,
					redirect: "/private/dashboard",
				},
			},
			{
				name: "locations",
				component: Locations,
				onlyIf: {
					guard: isAdmin,
					redirect: "/private/dashboard",
				},
			},
		],
	},
];

</script>

<Modal>
	<Router {routes}/>
</Modal>
