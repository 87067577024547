<script>

import LayoutCentered from "LayoutCentered.svelte";

export let currentRoute;
export let params;

</script>

<style>

h1 {
	@apply text-red-700
}

</style>
<LayoutCentered>
	<div>
		<h1>404 Not Found</h1>
		<pre>
			{JSON.stringify(currentRoute)}
			{JSON.stringify(params)}
		</pre>
	</div>
</LayoutCentered>
