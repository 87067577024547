import './main.pcss';
import App from './App.svelte';

import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n';

import en_us from 'i18n/en-us.json';
import nl_nl from 'i18n/nl-nl.json';

addMessages('en-us', en_us);
addMessages('nl-nl', nl_nl);

init({
	fallbackLocale: 'en-us',
	initialLocale: getLocaleFromNavigator()
});

const app = new App({
	target: document.body
});

export default app;
