<svelte:head>
	<title>WIS : {$_('dashboard')}</title>
</svelte:head>
<script>

import {_} from 'svelte-i18n';

import Tile from 'Tile.svelte';
import ButtonBar from 'buttons/ButtonBar.svelte';
import ClusterSelect from "ClusterSelect.svelte";
import {onMount} from 'svelte';

import { navigateTo } from 'svelte-router-spa';

import {getContext} from 'svelte';

import PNotify from "pnotify/PNotify.bundle";

import {clusters, presentations} from "./stores";
export let currentRoute;

const client = getContext('REST_CLIENT');
let TICKERTAPE_DEFAULTS = {publish_from:null,publish_until:null,text:'',speed:null,pause:null};
let WAITINGTIMES_DEFAULTS = [
	{
		active: false,
		name: '',
		delay: 0
	},
	{
		active: false,
		name: '',
		delay: 0
	},
	{
		active: false,
		name: '',
		delay: 0
	},
	{
		active: false,
		name: '',
		delay: 0
	},
	{
		active: false,
		name: '',
		delay: 0
	}
];

let items = {settings:{waiting_times:WAITINGTIMES_DEFAULTS,tickertape:TICKERTAPE_DEFAULTS},planned:[],inactive:[],embedded:[],shared:[],embeddable:[]};

$:selectedClusterId = (currentRoute.namedParams && currentRoute.namedParams.cluster_id) ? currentRoute.namedParams.cluster_id : ($clusters.length > 0 ? $clusters[0].id : null);

import Flatpickr from "Flatpickr.svelte";

const FLATPICKR_OPTIONS = {
	enableTime: true,
	dateFormat: "Y-m-d H:i",
	time_24hr: true,
	defaultHour: 0,
};

onMount(async () => {
	if(selectedClusterId) {
		selectCluster(selectedClusterId);
	}
});

function handleClick(id) {
	navigateTo('/private/presentation/' + id);
}

function handleAddClick(e) {
	presentations.create({
		label: $_('new_presentation')
	}).then(res => res.json()).then(data => {
		PNotify.success({
			title: $_('presentation') + ' ' + $_('created')
		});
		handleClick(data.id);
	});
}

async function selectCluster(cluster_id) {
	currentRoute.namedParams.cluster_id = cluster_id;
	selectedClusterId = cluster_id;
	navigateTo('/private/dashboard/' + selectedClusterId);
	const res = await client.get(`/presentations/dashboard/${selectedClusterId}`);
	items = await res.json();
	// console.log(items.settings.tickertape);
	if(!items.settings.tickertape) {
		items.settings.tickertape  = TICKERTAPE_DEFAULTS;
	}
	// console.log(items.settings.waiting_times);
	if(!items.settings.waiting_times){
		items.settings.waiting_times = WAITINGTIMES_DEFAULTS;
	}
}

async function handleClusterSelect(e) {
	selectCluster(e.detail.id);
}



function onSettingsSubmit(event) {
	let frm = event.target;
	if (!frm.checkValidity()) {
		return;
	}

	let req = {settings: {
		waiting_times: items.settings.waiting_times,
		tickertape: items.settings.tickertape
	}};

	let res = client.patch(`/clusters/${selectedClusterId}`, req).then(async res => {
		PNotify.success({
			title: $_('saved')
		});
		// let data = await res.json();
	}).catch(e=>{
		PNotify.error({
			title: e
		})
	});
}

function clearPublicationDates(e) {
	items.settings.tickertape.publish_from = null;
	items.settings.tickertape.publish_until = null;
}


</script>
<style>

header {
	height: 58px;
	@apply capitalize bg-gray-900 text-white p-4 flex w-full;
}

/*header {*/
/*	@apply w-full;*/
/*	display: flex;*/
/*	height: 58px;*/
/*	!*position: -webkit-sticky; !* Safari *!*!*/
/*	!*position: sticky;*!*/
/*	top: 0;*/
/*	font-size: 1.2rem;*/
/*	font-weight: bold;*/
/*	background-color: #1a202c;*/
/*	color: #fff;*/
/*	padding: 4px 8px;*/
/*	text-transform: capitalize;*/
/*	z-index: 1;*/
/*}*/

.list > div {
	@apply flex flex-row;
	flex-flow: row wrap;
	padding: 0 0 0 4px;
	min-height: 200px;
}

.list div > .tile {
	padding: 4px 4px 4px 0;
}

.list div > .tile.clickable {
	@apply cursor-pointer;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	font-size: 80%;
	color: #fff;
	text-align: left;
	vertical-align: top;
	border-top: 1px solid var(--bg-highlight);
	text-transform: uppercase;
	user-select: none;
	z-index: 1;
}

th, td {
	padding: 4px 8px;
}

thead {
	background-color: var(--bg-light);
}

.cb {
	width: 45px;
	text-align: center;
	vertical-align: middle;
}

</style>
<section>
	{#if $clusters.length}
	<ClusterSelect
		selectedId={selectedClusterId}
		on:select={handleClusterSelect}
	/>
	{/if}

	<div class="h-full overflow-y-auto pb-4">

		<form class="w-full" on:submit|preventDefault={onSettingsSubmit}>

			<div class="list">
				<header>
					{$_('waiting times')}
				</header>
				<div>
					{#await items}
						LOADING
					{:then}
					<table class="w-full border-collapse text-left">
					<thead class="align-top">
					<tr>
						<th></th>
						<th>{$_('name')}</th>
						<th>{$_('delay')}</th>
					</tr>
					{#each items.settings.waiting_times as item, i}
						<tr>
							<td class="cb"><input type="checkbox" checked={item.active==1} on:change={(e)=>item.active=e.target.checked?1:0}></td>
							<td><input type="text" bind:value={item.name} class="w-full p-2"/></td>
							<td><input type="text" bind:value={item.delay} class="w-full p-2"/></td>
						</tr>
					{/each}
					</table>
					{/await}
				</div>
			</div>
			<div>
				<header>
					{$_('announcements')}
				</header>
				{#await items}
					LOADING
				{:then}
				<div class="flex items-center justify-center">
					<div class="w-1/5">
						<label class="block text-gray-500 capitalize m-2 pr-1">
							{$_('message')}
						</label>
					</div>
					<div class="w-4/5 flex">
						<input type="text" bind:value={items.settings.tickertape.text} class="w-full p-2 float-r"/>
					</div>
				</div>

				<div class="flex items-center justify-center">
					<div class="w-1/5">
						<label
							for="speed"
							class="block text-gray-500 capitalize m-2 pr-1"
						>{$_("speed")}</label>
					</div>
					<div class="w-4/5 flex">
						<input id="speed" type="number" bind:value={items.settings.tickertape.speed} class="p-2"/>
					</div>
				</div>

				<div class="flex items-center justify-center">
					<div class="w-1/5">
						<label
							for="pause"
							class="block text-gray-500 capitalize m-2 pr-1"
						>{$_("pause")}</label>
					</div>
					<div class="w-4/5 flex">
						<input id="pause" type="number" bind:value={items.settings.tickertape.pause} class="p-2"/>
					</div>
				</div>

				<div class="flex items-center justify-center">
					<div class="w-1/5">
						<label
							for="publish_from"
							class="block text-gray-500 capitalize m-2 pr-1"
						>{$_("from")}</label
						>
					</div>
					<div class="w-4/5 flex">
						<Flatpickr
							options={FLATPICKR_OPTIONS}
							dateFormat="Y-m-d H:i"
							bind:value={items.settings.tickertape.publish_from}
							bind:formattedValue={items.settings.tickertape.publish_from}
						/>
					</div>
				</div>
				<div class="flex items-center justify-center">
					<div class="w-1/5">
						<label
							for="publish_until"
							class="block text-gray-500 capitalize m-2 pr-1"
						>{$_("until")}</label
						>
					</div>
					<div class="w-4/5 flex">
						<Flatpickr
							options={FLATPICKR_OPTIONS}
							dateFormat="Y-m-d H:i"
							bind:value={items.settings.tickertape.publish_until}
							bind:formattedValue={items.settings.tickertape.publish_until}
						/>
					</div>
				</div>
				<div class="p-4">
					<button
						type="submit"
						class="bg-green-300 hover:bg-blue-400 text-gray-900 text-xs px-4 py-2 m-2 rounded float-right"
					><b>✓</b> {$_("save")}</button
					>
					{#if items.settings.tickertape.publish_from || items.settings.tickertape.publish_until}
						<button
							on:click={clearPublicationDates}
							class="bg-red-300 hover:bg-red-400 text-gray-900 text-xs px-4 py-2 m-2 rounded float-right"
						>✖ {$_("clear publication date")}
						</button>
					{/if}
				</div>
				{/await}
			</div>
		</form>
	</div>

	<div class="h-full overflow-y-auto pb-40">
		{#await items}
			LOADING
		{:then}
			<div class="list">
				<header>{$_('planned presentations')}</header>
				<div>
					{#if items.planned.length}
						{#each items.planned as item, i}
							<div class="tile clickable">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
									  on:click={(e)=>handleClick(item.id)}/>
							</div>
						{/each}
					{:else}
						{$_('no presentations planned')}
					{/if}
				</div>
				<header>{$_('embedded presentations')}</header>
				<div>
					{#if items.embedded.length}
						{#each items.embedded as item, i}
							<div class="tile">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
								/>
							</div>
						{/each}
					{:else}
						{$_('no embedded presentations')}
					{/if}
				</div>
				<header>{$_('embeddable presentations')}</header>
				<div>
					{#if items.embeddable.length}
						{#each items.embeddable as item, i}
							<div class="tile clickable">
								<Tile thumbnail={item.thumbnail}
									  modified={item.modified??item.created}
									  label={item.label}
									  on:click={(e)=>handleClick(item.id)}/>
							</div>
						{/each}
					{:else}
						{$_('no embeddable presentations')}
					{/if}
				</div>
			</div>
		{/await}
	</div>
</section>
