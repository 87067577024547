<script>
	import { getContext, onMount } from "svelte";
	import { _ } from "svelte-i18n";

	import Group from "../node_modules/Group.svelte";

	const auth = getContext("AUTH_SERVICE");
	const client = getContext("REST_CLIENT");

	let item;
	let group_id = auth.profile.group_id;

	onMount(async () => {
		getGroup();
	});

	async function getGroup(gid) {
		if (gid) {
			group_id = gid;
		}
		client
			.get(
				`/groups/${group_id}?include=Tags,CanShareWith&_rel=Tags,CanShareWith`
			)
			.then(async (res) => res.json())
			.then((data) => {
				item = addSettings(data);
			});
	}

	function addSettings(data) {
		if (
			!data.settings ||
			!data.settings.hasOwnProperty("header_font_family")
		) {
			data.settings = {
				header_font_family: "Helvetica",
				header_font_size: "24",
				header_font_color: "#000000",
				header_background_color: "#FFFFFF",
				body_font_family: "Helvetica",
				body_font_size: "24",
				body_font_color: "#000000",
				body_background_color: "#FFFFFF",
			};
		}
		return data;
	}

	async function handleSubmit(e) {
		getGroup();
	}

</script>

<svelte:head>
	<title>WIS : {$_("settings")}</title>
</svelte:head>

<section class="p-8">
	{#await item}
		...
	{:then}
	<Group bind:item callback={handleSubmit} />
	{/await}
</section>
