<svelte:head>
	<title>WIS : {$_('users')}</title>
</svelte:head>
<script>

import User from 'User.svelte';
import GroupSelect from 'GroupSelect.svelte';
import {_} from 'svelte-i18n';

import Table from 'Table.svelte';
import {getContext, onMount} from 'svelte';
import {ConfirmModal, copy, NoticeModal} from 'utils';

import ButtonBar from 'buttons/ButtonBar.svelte';

export let currentRoute;

const {open} = getContext('simple-modal');
const client = getContext('REST_CLIENT');
const auth = getContext('AUTH_SERVICE');

let isDirty = false;
let backup = undefined;
let selectedUser = undefined;

let selectedId = currentRoute.namedParams && currentRoute.namedParams.id ? currentRoute.namedParams.id : auth.profile.group_id;
let rows = [];
const columns = [
	{
		key: 'name',
		title: $_('name'),
		value: v => v.first_name + ' ' + v.last_name,
		sortable: true
	},
	{
		key: 'email',
		title: $_('email'),
		value: v => v.email,
		sortable: true
	},
	{
		key: 'role',
		title: $_('role'),
		value: v => v.role,
		sortable: true
	},
	{
		key: 'status',
		title: $_('status'),
		value: v => v.status,
		sortable: true
	},
	{
		key: 'last_seen',
		title: $_('last seen'),
		value: v => v.last_seen || '',
		sortable: true
	}
];

if (auth.profile.role === 'superuser') {
	columns.push({
		key: 'action_sign_in_as',
		title: $_('actions'),
		value: () => '<button class="bg-blue-300 hover:bg-blue-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">' + $_('sign in as') + '</button>',
		headerClass: 'actions'
	}, {
		key: 'action_remove',
		title: '',
		value: () => '<button class="bg-red-300 hover:bg-red-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">✖ ' + $_('remove') + '</button>'
	}, {
		key: 'action_edit',
		title: '',
		value: () => '<button class="bg-blue-300 hover:bg-blue-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">✎ ' + $_('edit') + '</button>'
	});
} else {
	columns.push({
		key: 'action_remove',
		title: $_('actions'),
		value: () => '<button class="bg-red-300 hover:bg-red-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">✖ ' + $_('remove') + '</button>',
		headerClass: 'actions'
	}, {
		key: 'action_edit',
		title: '',
		value: () => '<button class="bg-blue-300 hover:bg-blue-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">✎ ' + $_('edit') + '</button>'
	});
}


onMount(async () => {
	fetchUsers(selectedId);
});

function handleCellClick(e) {
	switch (e.detail.key) {
		case 'action_sign_in_as':
			auth.signInAs(e.detail.row.id).then(profile => {
				NoticeModal('User Changed', 'You are now signed in as ' + profile.first_name + ' ' + profile.last_name);
				timerId = setTimeout(() => document.location.reload(), 2000);
			}).catch(error => {
				console.error('authService.signInAs', error);
			});
			break;
		case 'action_remove':
			let notice = ConfirmModal('Are you sure?', 'Please confirm you wish to delete this');
			notice.on('confirm', () => {
				client.delete(`/users/${e.detail.row.id}`).then((e) => {
					fetchUsers(selectedId);
				});
			});
			break;
		case 'action_edit':
			showUser(copy(e.detail.row));
			break;
	}
}

function handleAddClick(e) {
	showUser({
		group_id: selectedId,
		role: 'user',
		locale: 'nl-nl',
		status: 'active'
	});
}

async function fetchUsers(group_id) {
	const res = await client.get(`/users?filter[group_id]=${group_id}&filter[status]=active`);
	rows = await res.json();
}

function handleGroupSelect(e) {
	selectedId = e.detail.id;
	fetchUsers(e.detail.id);
}

async function editUserCallback(o) {
	let res = await o.json();
	if (await res.id === auth.profile.id) {
		auth.signOut().then((e) => {
			document.location.reload();
		});
	} else {
		fetchUsers(selectedId);
	}
}

function showUser(o) {
	open(User, {item: o, callback: editUserCallback});
}

</script>
<style>

</style>
<section class="h-full">
	<GroupSelect bind:selectedId on:select={handleGroupSelect}/>
	<ButtonBar on:add={handleAddClick}/>
	<Table {columns} {rows} sortBy="id" on:clickCell={handleCellClick}/>
</section>
