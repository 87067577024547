<svelte:head>
	<title>WIS : {$_('screens')}</title>
</svelte:head>
<script>

import ClusterSelect from "../node_modules/ClusterSelect.svelte";
import {_} from 'svelte-i18n';
import {ConfirmModal, copy} from 'utils';
import {getContext, onMount} from 'svelte';
import {navigateTo} from 'svelte-router-spa';

import Table from 'Table.svelte';
import ButtonBar from "buttons/ButtonBar.svelte";
import Screen from 'Screen.svelte';

import {clusters} from "./stores";

export let currentRoute;

let selectedClusterId = currentRoute.namedParams && currentRoute.namedParams.cluster_id ? currentRoute.namedParams.cluster_id : undefined;

let backup;

const client = getContext('REST_CLIENT');

let rows = [];
const {open} = getContext('simple-modal');

const columns = [
	// {
	// 	key: 'uuid',
	// 	title: 'uuid',
	// 	value: v => v.uuid,
	// 	sortable: true
	// },
	{
		key: 'label',
		title: $_('label'),
		value: v => v.label,
		sortable: true,
		class: 'text-sm'
	},
	{
		key: 'location',
		title: $_('location'),
		value: v => v._inc[1].label,
		sortable: true,
		class: 'text-sm'
	},
	{
		key: 'last_seen',
		title: $_('last seen'),
		value: v => v.status ? v.status : '' + ' ' + v.last_seen,
		sortable: true
	},
	{
		key: 'radio_channel_id',
		title: $_('radio'),
		value: v => v._inc[0].label,
		sortable: true
	}
	, {
		key: 'action_remove',
		title: $_('actions'),
		value: () => '<button class="bg-red-300 hover:bg-red-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">✖ ' + $_('remove') + '</button>',
		headerClass: 'actions'
	}, {
		key: 'action_edit',
		title: '',
		value: () => '<button class="bg-blue-300 hover:bg-blue-400 text-gray-900 text-xs px-4 py-2 rounded whitespace-no-wrap">⧉ ' + $_('edit') + '</button>'
	}
];

onMount(async () => {
	fetchScreens(currentRoute.namedParams.cluster_id);
});

async function fetchScreens(cluster_id) {
	const rows_res = await client.get(`/screens?filter[cluster_id]=${cluster_id}&include=RadioChannel,Location&_rel=RadioChannel,Locations&sort=label`);
	rows = await rows_res.json();
	backup = copy(rows);
}

function handleClusterSelect(e) {
	navigateTo('/private/screens/' + e.detail.id);
	fetchScreens(e.detail.id);
}

function handleCellClick(e) {
	switch (e.detail.key) {
		case 'action_remove':
			let notice = ConfirmModal('Are you sure?', 'Please confirm you wish to delete this');
			notice.on('confirm', () => {
				client.delete(`/screens/${e.detail.row.id}`).then((ev) => {
					fetchScreens(e.detail.row.cluster_id);
				});
			});
			break;
		case 'action_edit':
			showEdit(copy(e.detail.row));
			break;
	}
}

function editCallback(e) {
	fetchScreens(currentRoute.namedParams.cluster_id);
}


function handleAddClick(e) {
	showEdit({cluster_id: selectedClusterId, label: 'New Screen', width: 1920, height: 1080, xpos: 0, ypos: 0, radio_channel_id: 1});
}

function showEdit(o) {
	open(Screen, {item: o, callback: editCallback});
}

</script>
<style>

</style>
<section class="h-full">
	{#if $clusters.length}
		<ClusterSelect bind:selectedId={selectedClusterId}
					   on:select={handleClusterSelect}
					   showEdit={true}
		/>
	{/if}
	<ButtonBar on:add={handleAddClick}/>
	<Table {columns} {rows} sortBy="id" on:clickCell={handleCellClick}/>
</section>
