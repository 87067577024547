<!--suppress ALL -->
<svelte:head>
	<title>WIS : {$_('presentation')}</title>
</svelte:head>
<script>

import env from 'env.production';
import Tile from 'Tile.svelte';
import {Tab, TabList, TabPanel, Tabs} from 'tabs';
import {getContext, onMount} from 'svelte';
import {ConfirmWithSlideModal, copy, showFullscreen} from 'utils.js';
import Button from 'buttons/Button.svelte';
import FullscreenIcon from 'icons/Fullscreen.svelte';
import PlusIcon from 'icons/Plus.svelte';
import Popper from 'Popper.svelte';
import {_} from 'svelte-i18n';
import Template from 'Template.svelte';
import Sortable from 'sortablejs';
import PresentationForm from 'PresentationForm.svelte';
import PNotify from 'pnotify/PNotify.bundle';
import SlideInspector from 'SlideInspector.svelte';
import {presentations} from "../private/stores";
import SlidePicker from 'SlidePicker.svelte';

let openPresentationPicker, openFilePicker;

export let currentRoute;

const {open} = getContext('simple-modal');

const client = getContext('REST_CLIENT');

let TEMPLATES = {
	static: [
		'video',
		'image',
		'cover',
		'title_text',
		'title_text_sidebar',
		'title_text_image',
		'title_text_image_rev',
		'slide'
	],
	dynamic: [
		'flash',
		'rss',
		'wachttijden',
		'weather'
	],
	external: [
		'presentation',
		'url'
	]
};

let popper_reference, popper_is_visible;
let presentation;
let slideIndex = undefined;

let preview;
let sortable, sortable_slides;

let slides = [];
let presentation_slides = [];

let isDirty = false;
let activeSlideBackup;
$:activeSlide = (slides.length && slides[slideIndex]) ? slides[slideIndex] : undefined;
$:presentationSlide = (slides.length && slides[slideIndex]) ? presentation_slides[slideIndex] : undefined;
let _ZOOM_START = 100;

$: if(activeSlide && preview) {
	sendSlideParameters();
}

onMount(async () => {
	const presentation_res = await client.get(`/presentations/${currentRoute.namedParams.id}?include=Slides,PresentationSlides,Tags&_rel=Slides,PresentationSlides,Tags`);
	presentation = await presentation_res.json();
	slides = await presentation._inc.filter((e) => e._type === 'Slide');
	presentation_slides = await presentation._inc.filter((e) => e._type === 'PresentationSlides');
	sortable = Sortable.create(sortable_slides, {
		onSort: onSortHandler,
		forceFallback: true // required for Safari @see https://github.com/SortableJS/Sortable/issues/1571
	});
	if (slides.length) {
		selectSlide(slides[0], 0);
	}
});

/*************************************************/

// https://stackoverflow.com/questions/9153445/how-to-communicate-between-iframe-and-the-parent-site#9154267
function isValidOrigin(o) {
	return env.API === o;
}

function sendPostMessage(data) {
	preview.contentWindow.postMessage(data, '*');
}

function handlePostMessage(e) {
	if (!isValidOrigin(e.origin)) {
		return;
	}

	switch (e.data.type) {
		case 'header':
			// console.log('PARENT RECEIVE header', e.data.value);
			slides[slideIndex].parameters.header = e.data.value;
			break;
		case 'body':
			// console.log('PARENT RECEIVE body', e.data.value);
			slides[slideIndex].parameters.body = e.data.value;
			break;
	}
}

window.addEventListener('message', handlePostMessage, false);

/*************************************************/


function slidePickerCallback(id, index) {
	doSlideDuplicate(id, presentation.id, index);
}

function addSlide(tmpl) {
	console.log('addSlide', tmpl);
	let insert_index = slideIndex === undefined ? 0 : slideIndex + 1;
	if (tmpl === 'slide') {
		// existing slide
		popper_is_visible = false;
		const large_window = {width: '80vw', height: '80vh'};
		open(SlidePicker, {index: insert_index, callback: slidePickerCallback}, {
			window: large_window,
			content: large_window
		});
	} else {
		let result = client.post(`/presentations/${presentation.id}/create`, {
			type: tmpl,
			index: insert_index
		}).then(async res => {
			let data = await res.json();
			slides.splice(insert_index, 0, data.slide);
			slides = slides;
			popper_is_visible = false;
			presentation_slides.splice(insert_index, 0, data.presentation_slide);
			presentation_slides = presentation_slides;
			selectSlide(data.slide, insert_index);
			// if the type =
			if(tmpl==='presentation'){
				openPresentationPicker();
			}
			if(tmpl==='video') {
				openFilePicker(tmpl);
			}
			if(tmpl==='image' || tmpl==='cover') {
				openFilePicker(tmpl);
			}
		});
	}
}

function selectSlide(slide, index) {
	// console.log('selectSlide', slide, index);
	activeSlideBackup = copy(slide);
	slideIndex = index;
}

function removeSlideIndex(index) {
	slides.splice(index, 1);
	slides = slides;
	if (index <= slideIndex) {
		if (slides.length) {
			slideIndex = Math.max(0, slideIndex - 1);
		} else {
			slideIndex = undefined;
		}
	}
}

function handleSlideRemove(index) {
	// console.log('handleSlideRemove', index);
	let notice = ConfirmWithSlideModal('Are you sure?', 'Please confirm you wish to delete this');
	notice.on('confirm', () => {
		let result = client.delete(`/presentations/${presentation.id}/slides/${index}`).then(async (res) => {
			removeSlideIndex(index);
		});
	});
	notice.on('confirm-slide', () => {
		let result = client.delete(`/presentations/${presentation.id}/slides/${index}?delete_slide=1`).then(async (res) => {
			removeSlideIndex(index);
		});
	});
}

function doSlideDuplicate(slide_id, presentation_id, insert_index) {
	let result = client.post(`/slides/${slide_id}/duplicate`, {
		presentation_id: presentation_id,
		index: insert_index
	}).then(async (res) => {
		let data = await res.json();
		slides.splice(insert_index, 0, data.slide);
		slides = slides;
		slideIndex = insert_index;
		presentation_slides.splice(insert_index, 0, data.presentation_slide);
		presentation_slides = presentation_slides;
	});
}

function handleSlideDuplicate(index) {
	console.log('handleSlideDuplicate', index);
	let clickedSlide = slides[index];
	let insert_index = index + 1;
	doSlideDuplicate(clickedSlide.id, presentation.id, insert_index);
}

/*************************************************/

let _WIDTH = 1920;
let _HEIGHT = 1080;

let HEADER_FONT_FAMILY = 'Assistant';
let HEADER_FONT_SIZE = 24;
let HEADER_FONT_COLOR = '#FFF';
let HEADER_BACKGROUND_COLOR = '#000000';

let BODY_FONT_FAMILY = 'Assistant';
let BODY_FONT_SIZE = 24;
let BODY_FONT_COLOR = '#FFFFFF';
let BODY_BACKGROUND_COLOR = '#000000';

function onSlideLoad(e) {
	// console.log('onSlideLoad', slides[slideIndex]);
	fitZoom();
}

function sendSlideParameters() {
	// console.log('sendSlideParameters');
	// sendPostMessage({type: 'style', className: 'slide', style: 'background', value: `url("https://picsum.photos/${_WIDTH}/${_HEIGHT}") no-repeat`});
	// sendPostMessage({type: 'style', className: 'slide', style: 'background', value: `url("${url}") no-repeat`});

	sendPostMessage({type: 'style', className: 'slide', style: 'width', value: _WIDTH + 'px'});
	sendPostMessage({type: 'style', className: 'slide', style: 'height', value: _HEIGHT + 'px'});

	if(['cover', 'title_text', 'title_text_sidebar', 'title_text_image', 'title_text_image_rev', 'rss', 'wachttijden', 'weather'].includes(slides[slideIndex].type)) {
		sendPostMessage({type: 'style', className: 'header_font_family', style: 'font-family', value: activeSlide.parameters.header_font_family});
		sendPostMessage({type: 'style', className: 'header_font_size', style: 'font-size', value: activeSlide.parameters.header_font_size + 'px'});
		sendPostMessage({type: 'style', className: 'header_font_color', style: 'color', value: activeSlide.parameters.header_font_color});
		sendPostMessage({type: 'style', className: 'header_background_color', style: 'background-color', value: activeSlide.parameters.header_background_color});
	}


	if(['title_text', 'title_text_sidebar', 'title_text_image', 'title_text_image_rev', 'rss', 'wachttijden', 'weather'].includes(slides[slideIndex].type)) {
		sendPostMessage({type: 'style', className: 'body_font_family', style: 'font-family', value: activeSlide.parameters.body_font_family});
		sendPostMessage({type: 'style', className: 'body_font_size', style: 'font-size', value: activeSlide.parameters.body_font_size + 'px'});
		sendPostMessage({type: 'style', className: 'body_font_color', style: 'color', value: activeSlide.parameters.body_font_color});
		sendPostMessage({type: 'style', className: 'body_background_color', style: 'background-color', value: activeSlide.parameters.body_background_color});
	}

	if(['rss'].includes(slides[slideIndex].type)) {
		sendPostMessage({type: 'param', name: 'PAGE_INTERVAL', value: activeSlide.parameters.interval});
	}
}

function handleSlideSubmit(event) {
	// console.log('handleSlideSubmit', event);
	let req = event.detail.slide;
	const slide = event.detail.slide;
	const presentation_slide = event.detail.presentation_slide;
	req._rel = {
		'PresentationSlides': presentation_slide
	};
	let res = client.patch(`/slides/${slide.id}`, req).then(async res => {
		PNotify.success({
			title: $_('saved')
		});
		let data = await res.json();
		slides[presentation_slide.index] = data;
		slides = slides;
		slideIndex = presentation_slide.index;
		presentation_slides[presentation_slide.index] = presentation_slide;
		presentation_slides = presentation_slides;
		presentations.init();
		handleSlideUpdate({detail: data});
	});
}

function handleSlideUpdate(event) {
	// console.log('handleSlideUpdate', event);

	preview.src += "";

	// reload the slides list with new data?
	// not for now... preview reload is enough, otherwise one assumes the slide was saved
	//
	// const slide = event.detail;
	// slides.forEach((i, index) => {
	// 	if (i.id === slide.id) {
	// 		slides[index] = slide;
	// 	}
	// });
	// slides = slides;
}

/*************************************************/

function editPresentationCallback(o) {
	presentations.init();
	// console.log('editPresentationCallback', o);
}

function editPresentation(o) {
	// console.log('editPresentation', o);
	open(PresentationForm, {item: o, callback: editPresentationCallback});
}

function onSortHandler(e) {
	// console.log('onSortHandler', e);
	let promise = client.patch(`/presentations/${presentation.id}/move`, {
		old_index: e.oldIndex,
		new_index: e.newIndex
	});
}


/*************************************************/

let _ZOOM = 100;

function scale(dir) {
	if (dir === '-') {
		if (_ZOOM - 5 >= 25) {
			setZoom(_ZOOM - 10);
		}
	} else if (dir === '+') {
		if (_ZOOM + 5 <= 200) {
			setZoom(_ZOOM + 10);
		}
	}
}

function setZoom(e) {
	_ZOOM = e;
	sendPostMessage({type: 'zoom', value: _ZOOM});
}

function fitZoom() {
	let e = 100;
	if(activeSlide) {
		if (preview.offsetWidth < _WIDTH) {
			let perc = (preview.offsetWidth / _WIDTH).toFixed(2)*100;
			e = perc - (perc % 5);
		}
		setZoom(e);
	}
	return e;
}

</script>
<style>

section {
	/*@apply flex w-full h-full;*/
	@apply flex w-full;
	height: 100%;
	/*overflow: hidden;*/
	/*border: 1px solid #f60;*/
}

header {
	height: 58px;
	@apply bg-gray-900 text-white p-4 flex w-full capitalize;
}

header div:first-child {
	@apply flex-grow;
}

nav {
	flex: 0 0 207px;
	height: 100%;
	@apply bg-gray-800 overflow-hidden;
	/*width: 207px;*/
	padding-bottom: 58px;
}

article {
	height: 100%;
	@apply bg-black;
	flex: 1 1;
}

aside {
	flex: 0 0 247px;
	height: 100%;
	@apply bg-gray-800 overflow-hidden;
	padding-bottom: 58px;
}

.templates {

}

.templates li {
	padding: 5px 5px 30px 5px;
	display: inline-block;
	float: left;
}

.slides li {
	@apply select-none;
	padding-left: 8px;
	cursor: grab;
}

.slides li:hover,
.slides li.active {
	@apply bg-red-800;
}

.preview:fullscreen {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
}

.preview {
	@apply flex items-center justify-center bg-black;
	padding: 10px;
	height: calc(100% - 58px);
}

/* .flash_preview {
	display: none;
} */

iframe {
	overflow: scroll;
	display: table-row;
	border: none;
	width: 100%;
	height: 100%;
}

.scale {
	padding: 4px 10px;
	font-weight: normal;
}

</style>

<section>
	<nav>
		<header>
			<div>{$_('slides')}</div>
			<div>
				<Button bind:buttonElement={popper_reference}
						icon={PlusIcon}
						on:click={()=>popper_is_visible=!popper_is_visible}
				/>
			</div>
		</header>
		<div class="h-full overflow-y-scroll">
			<ol class="slides" bind:this={sortable_slides}>
				{#await presentation}
					{$_('loading')}...
				{:then presentation }
					{#each slides as slide, i}
						<li class:active={slideIndex===i} on:click={()=>selectSlide(slide,i)}>
							<Tile bind:thumbnail={slide.thumbnail}
								  modified={slide.modified??slide.created}
								  label={slide.label}
								  showDuplicateButton
								  showRemoveButton
								  on:handleRemoveClick={(e)=>handleSlideRemove(i)}
								  on:handleDuplicateClick={(e)=>handleSlideDuplicate(i)}
							/>
						</li>
					{/each}
				{/await}
			</ol>
		</div>
	</nav>

	<article>
		<header>
			<div>{$_('preview')}</div>
			<div>
				<span class="scale">
					Zoom {_ZOOM}%
					<Button on:click={e=>scale('-')}> - </Button>
					<input id="zoom" bind:value={_ZOOM} type="range" min="25" max="200" step="5" on:input={e=>setZoom(_ZOOM)}>
					<Button on:click={e=>scale('+')}> + </Button>
				</span>

				<Button icon={FullscreenIcon} on:click={e => showFullscreen(preview)}/>
				<Button on:click={()=>editPresentation(presentation)}>✎ {$_('edit')}</Button>
			</div>
		</header>
		<div class="preview">
			{#if activeSlide}
				<iframe bind:this={preview} src={env.API + '/slides/' + activeSlide.id + '/render?mode=editor' } on:load={onSlideLoad} title="preview"></iframe>
			{/if}
		</div>
	</article>

	<aside>
		<header>{$_('inspector')}</header>
		<SlideInspector
				bind:slide={activeSlide}
				bind:presentation_slide={presentationSlide}
				bind:isDirty={isDirty}
				bind:openPresentationPicker={openPresentationPicker}
				bind:openFilePicker={openFilePicker}
				on:update={handleSlideUpdate}
				on:submit={handleSlideSubmit}
		/>
	</aside>

</section>

<Popper bind:is_visible={popper_is_visible} bind:reference={popper_reference}>
	<Tabs>
		<TabList>
			<Tab>{$_('static')}</Tab>
			<Tab>{$_('dynamic')}</Tab>
			<Tab>{$_('external')}</Tab>
		</TabList>
		<TabPanel>
			<ul class="templates">
				{#each TEMPLATES.static as tmpl}
					<li>
						<Template {tmpl} on:click={()=>addSlide(tmpl)}/>
					</li>
				{/each}
			</ul>
		</TabPanel>
		<TabPanel>
			<ul class="templates">
				{#each TEMPLATES.dynamic as tmpl}
					<li>
						<Template {tmpl} on:click={()=>addSlide(tmpl)}/>
					</li>
				{/each}
			</ul>
		</TabPanel>
		<TabPanel>
			<ul class="templates">
				{#each TEMPLATES.external as tmpl}
					<li>
						<Template {tmpl} on:click={()=>addSlide(tmpl)}/>
					</li>
				{/each}
			</ul>
		</TabPanel>
	</Tabs>
</Popper>

<!--<div class="preview flash_preview" bind:this={preview}>-->
<!--	<Preview presentation_id={currentRoute.namedParams.id} width="100%" height="100%"/>-->
<!--</div>-->
