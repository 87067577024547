<script>

import {getContext} from 'svelte';
import {presentations, clusters, files} from './stores';
import {Route} from 'svelte-router-spa';
import Navigation from 'Navigation.svelte';

const auth = getContext('AUTH_SERVICE');

if (auth.profile) {
	presentations.init(`/presentations?filter[group_id]=${auth.profile.group_id}&sort=label`);
	clusters.init(`/clusters?filter[group_id]=${auth.profile.group_id}&sort=label`);
	files.init();
}

export let currentRoute;

</script>
<Navigation {currentRoute}/>
<Route {currentRoute}/>
