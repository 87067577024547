<svelte:head>
	<title>WIS : {$_('schedule')}</title>
</svelte:head>
<script>

import ScheduleRow from "ScheduleRow.svelte";
import ButtonBar from "buttons/ButtonBar.svelte";
import ClusterSelect from "ClusterSelect.svelte";
import PNotify from 'pnotify/PNotify.bundle';
import {_} from 'svelte-i18n';
import {navigateTo} from 'svelte-router-spa';

import {afterUpdate, getContext, onMount} from 'svelte';

import {blink, ConfirmModal, copy, hasEqualValues} from 'utils';

import {presentations, clusters} from "./stores";

export let currentRoute;

const client = getContext('REST_CLIENT');

let publishAll;
let promise, records = [], backup = [], deleted = [];


$:isDirty = !hasEqualValues(records, backup);
$:selectedClusterId = (currentRoute.namedParams && currentRoute.namedParams.cluster_id) ? currentRoute.namedParams.cluster_id : ($clusters.length > 0 ? $clusters[0].id : null);
$:defaultPresentationId = $presentations.length > 0 ? $presentations[0].id : null;
$:DEFAULTS = {
	presentation_id: defaultPresentationId,
	publish_from: null,
	publish_until: null,
	mo: 1,
	tu: 1,
	we: 1,
	th: 1,
	fr: 1,
	sa: 1,
	su: 1,
	start_time: '00:00',
	end_time: '24:00'
};

onMount(async () => {
	selectCluster(selectedClusterId);
});

async function handleSaveClick(e) {
	let promises = [];
	for (let i in records) {
		let schedule = records[i];
		if (schedule.id) {
			promises.push(client.patch('/schedules/' + schedule.id, schedule));
		} else {
			promises.push(client.post('/schedules', schedule).then(async (response) => {
				let data = await response.json();
				records[i].id = data.id;
			}));
		}
	}

	for (let i in deleted) {
		promises.push(client.delete('/schedules/' + deleted[i]));
	}

	promise = Promise.all(promises).then(() => {
		backup = copy(records);
		deleted = [];
		PNotify.success({
			title: 'Saved changes'
		});
	}, (error) => {
		console.log(error);
		PNotify.error({
			title: error
		});
	});
}

function handleRevertClick(e) {
	records = copy(backup);
	deleted = [];
	testState(records);
}

function handleAddRow() {
	DEFAULTS.cluster_id = parseInt(selectedClusterId);
	records.push(JSON.parse(JSON.stringify(DEFAULTS)));
	records = records;
	someThingToBlink = getSomethingToBlink;
}

function getSomethingToBlink() {
	return document.querySelector('table tbody').lastElementChild;
}

function handleDeleteClick(e) {
	if (e.detail.id) {
		deleted.push(e.detail.id);
	}
	records.splice(records.indexOf(e.detail), 1);
	records = records;
}

function handleEditClick(e) {
	if (isDirty) {
		const notice = ConfirmModal('Are you sure?', 'There are unsaved changes, are you sure you want to leave?');
		notice.on('confirm', () => {
			navigateTo('/private/presentation/' + e.detail.id);
		});
	} else {
		navigateTo('/private/presentation/' + e.detail.id);
	}
}

function handleDuplicateClick(e) {
	let duplicate = JSON.parse(JSON.stringify(e.detail));
	duplicate.id = null;
	records.push(duplicate);
	records = records;
	someThingToBlink = getSomethingToBlink;
}

async function fetchSchedules(cluster_id) {
	// console.log('fetchSchedules', cluster_id);
	return client.get(`/schedules?filter[cluster_id]=${cluster_id}`).then(async res => {
		if (res.ok) {
			records = await res.json();
			backup = copy(records);
			testState(records);
			return records;
		} else {
			throw Error(`getResults not ok; ${res.status}`);
		}
	});
}

async function selectCluster(cluster_id) {
	currentRoute.namedParams.cluster_id = cluster_id;
	selectedClusterId = cluster_id;
	navigateTo('/private/schedule/' + cluster_id);
	promise = fetchSchedules(cluster_id);
}

async function handleClusterSelect(e) {
	// console.log('handleClusterSelect', e);
	selectCluster(e.detail.id);
}

function testState(records) {
	let states = [];
	for (let i in records) {
		if (states.length) {
			if (states[0] !== records[i].active) {
				publishAll.indeterminate = true;
				break;
			}
		}
		states.push(records[i].active)
	}
}

function handlePublishAll(e) {
	for (let i in records) {
		if (records.hasOwnProperty(i)) {
			records[i].active = e.target.checked ? 1 : 0;
		}
	}
}

let someThingToBlink;
afterUpdate(() => {
	if (someThingToBlink) {
		blink(someThingToBlink());
		someThingToBlink = null;
	}
});

</script>
<style>

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	font-size: 80%;
	color: #fff;
	text-align: left;
	vertical-align: top;
	border-top: 1px solid var(--bg-highlight);
	text-transform: uppercase;
	user-select: none;
	z-index: 1;
}

th, td {
	padding: 4px 8px;
}

thead {
	background-color: var(--bg-light);
}

thead tr:nth-child(2) th {
	color: var(--txt-muted2);
	border-bottom: 1px solid var(--bg-highlight);
	border-left: 1px solid var(--bg-highlight);
	border-top: none;
}

thead tr:nth-child(1) th {
	border-left: 1px solid var(--bg-highlight);
	border-bottom: 1px solid var(--bg-highlight);
}

.no-bottom {
	border-bottom: none !important;
}

thead tr:nth-child(1) th:nth-child(1),
thead tr:nth-child(2) th:nth-child(1) {
	border-left: none;
}

.datetime {
	width: 111px;
}

.time {
	width: 60px;
}

.cb {
	width: 45px;
}

.cbc {
	text-align: center
}

.actions {
	width: 256px;
}

</style>
<section>
	<div class="h-full">
		{#if $clusters.length}
		<ClusterSelect
				selectedId={selectedClusterId}
				on:select={handleClusterSelect}/>
		{/if}
		<ButtonBar
				bind:isDirty={isDirty}
				on:cancel={handleRevertClick}
				on:save={handleSaveClick}
				on:add={handleAddRow}/>

		<table class="w-full border-collapse text-left">
		<thead class="align-top">
		<tr>
			<th colspan="3" class="no-bottom">{$_('publish')}</th>
			<th rowspan="2">{$_('presentation')}</th>
			<th colspan="7" class="no-bottom">{$_('weekday')}</th>
			<th colspan="2" class="no-bottom">{$_('timeslot')}</th>
			<th rowspan="2" class="actions">{$_('actions')}</th>
		</tr>
		<tr>
			<th class="cb cbc"><input type="checkbox" bind:this={publishAll} on:change={(e)=>handlePublishAll(e)}/>
			</th>
			<th class="datetime">{$_('from')}</th>
			<th class="datetime">{$_('until')}</th>
			<th class="cb">{$_('mo')}</th>
			<th class="cb">{$_('tu')}</th>
			<th class="cb">{$_('we')}</th>
			<th class="cb">{$_('th')}</th>
			<th class="cb">{$_('fr')}</th>
			<th class="cb">{$_('sa')}</th>
			<th class="cb">{$_('su')}</th>
			<th class="time">{$_('from')}</th>
			<th class="time">{$_('until')}</th>
		</tr>
		</thead>
		<tbody>
		{#await promise}
			<tr>
				<td colspan="12">LOADING... !</td>
			</tr>
		{:then}
			{#each records as schedule, i }
				<ScheduleRow
						bind:schedule
						selectedValue={$presentations.find(o=>o.id===schedule.presentation_id)}
						on:edit={handleEditClick}
								on:delete={handleDeleteClick}
								on:duplicate={handleDuplicateClick}/>
			{/each}
		{/await}
		</tbody>
		</table>
	</div>
</section>
